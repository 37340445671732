import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, useFormik, FormikProvider } from "formik";
import { initialValues, validationSchema } from "./util";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { PassRequirements } from "../common/PassRequirements";
import { register } from "../../../redux/AllThunk/Register";
const stateKey = "UserRegister";

const Register = (props) => {
  const [loading, setLoading] = useState(false);

  const submitHandler = (values) => {
    if (formik.isValid === true) {
      setLoading(true);

      const formData = {
        name: values.name,
        phone: values.phone,
        country_code: values.country_code,
        company_name: values.company_name,
        city:values.city
      };
      
      props.dispatch(
        register({
          payload: formData,
          key: stateKey,
        })
      );

      // props.dispatch({
      //   type: "User_register",
      //   payload: {
      //     data: {
      //       name: values.name,
      //       email: values.email,
      //       phone: values.phone,
      //       country_code: values.country_code,
      //       password: values.password,
      //       password_confirmation: values.cpass,
      //     },
      //   },
      //   key: stateKey,
      // });
    }
    //values.user_captcha = "";
  };
  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    setLoading(false);
    if (props.formResult.success == true){
      setTimeout(function () {
        props.dispatch({
          type: "RESET_FORM",
          key: stateKey,
        });
        window.location.href = "/catalogue";
      console.log('registered') ; 
    }, 2000);
    }
  }, [props.formResult]);

  return (
    <>
     
      
      <div className="main-layout d-flex registerPage">
        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
          QR Catalogue
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 ps-md-4">
          <h1 className="login-title">Create Account</h1>
        
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit} className="login-form">
              <Form.Group className="col-lg-9 col-md-9 col-sm-12">
                <Field name="name">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Full Name"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
{/* 
              <Form.Group className="col-lg-9 col-md-9 col-sm-12">
                <Field name="email">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="email"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Email ID"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group> */}

              <Form.Group className=" col-lg-9 col-md-9 col-sm-12 d-flex align-items-baseline">
                <Form.Group className="col-lg-1 col-md-1 col-sm-1">
                  +
                </Form.Group>
                <Form.Group className="col-lg-3 col-md-3 col-sm-3">
                  <Field name="country_code">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          type="text"
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                          placeholder="Country Code"
                        />
                        {meta.touched && meta.error && (
                          <Form.Control.Feedback type="invalid">
                            {meta.error}
                          </Form.Control.Feedback>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
                <Form.Group className="ms-2 col-lg-8 col-md-8 col-sm-8">
                  <Field name="phone">
                    {({ field, meta }) => (
                      <>
                        <Form.Control
                          {...field}
                          type="text"
                          autoComplete="off"
                          isInvalid={!!meta.error && !!meta.touched}
                          value={field.value ? field.value : ""}
                          placeholder="Mobile Number"
                        />
                        {meta.touched && meta.error && (
                          <Form.Control.Feedback type="invalid">
                            {meta.error}
                          </Form.Control.Feedback>
                        )}
                      </>
                    )}
                  </Field>
                </Form.Group>
              </Form.Group>

              <Form.Group className="col-lg-9 col-md-9 col-sm-12">
                <Field name="company_name">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Company Name"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>

              <Form.Group className="col-lg-9 col-md-9 col-sm-12">
                <Field name="city">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="City"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
                      
              <Form.Check className="d-flex col-lg-9 col-md-9 col-sm-12 mt-3">
                <Field
                  type="checkbox"
                  name="terms"
                  id="terms"
                  className="form-check-input mt-0"
                />
                <label
                  htmlFor="terms"
                  className="register-checkbox col-lg-10 col-md-10 col-sm-8"
                >
                  By clicking this box you are agreeing to the terms and
                  conditions 
                </label>
              </Form.Check>
              {formik.errors.terms && formik.touched.terms == true && (
                <div className="field-error mt-2">{formik.errors.terms}</div>
              )}
              <div className="col-md-12 col-sm-12 mt-4">
                <button className="button mt-auto" type="submit">
                  Register
                </button>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
};

export default connect((state) => {
  return { formResult: { ...state?.Forms?.[stateKey] } };
})(Register);
