import axios,{wp_instance} from "../interceptor";
import { api_base, HTTP_OK } from "../../constants";

export const share_via_email = (action) => {
  return (dispatch) => {
    axios({
      url: api_base + "/customer/share_via_email",
      method: "post",
      data: action.payload,
    }).then(
      (response) => {
        if (response?.status == HTTP_OK) {
          dispatch({
            type: "FORM_SUCCESS",
            payload: response?.data?.data,
            key: action.key,
          });
        } else {
          dispatch({
            type: "FORM_ERROR",
            payload: response?.response?.data.message,
            moreError: response?.response?.data,
            key: action.key,
          });
        }
      },
      (error) => {
        dispatch({
          type: "FORM_ERROR",
          payload: error?.response?.data.message,
          moreError: error?.response?.data,
          key: action.key,
        });
      }
    );
  };
};

export const share_via_whatsapp = (action)=>{
    return (dispatch) => {
        wp_instance({
          url: "https://wext.ainocular.com:8443/api/v1/chat/open/messages",
          method: "post",
          data: action.payload,
        }).then(
          (response) => {
            if (response?.status == HTTP_OK) {
              dispatch({
                type: "FORM_SUCCESS",
                payload: response?.data?.msg,
                key: action.key,
              });
            } else {
              dispatch({
                type: "FORM_ERROR",
                payload: response?.response?.data.message,
                moreError: response?.response?.data,
                key: action.key,
              });
            }
          },
          (error) => {
            dispatch({
              type: "FORM_ERROR",
              payload: error?.response?.data.message,
              moreError: error?.response?.data,
              key: action.key,
            });
          }
        );
    };
}