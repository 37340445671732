export const api_base = process.env.REACT_APP_BASE_URL;
export const base_url = process.env.REACT_APP_BASE_URL_ROUTE;
export const hosturl = process.env.REACT_APP_HOST_URL
export const web_base_url = process.env.REACT_APP_WEB_URL;
export const whatsapp_clientId = process.env.REACT_APP_WHATSAPP_CLIENT_ID;

export const HTTP_OK = 200;
export const HTTP_UNPROCESSABLE_ENTITY = 422;
export const HTTP_VALIDATION_ERROR = 400;

export const alphanumeric = /^[a-zA-Z0-9 ]*$/;
export const notOnlyWhiteSpace = /^\s*\S[\s\S]*$/;
export const numeric = /^[0-9]*$/;
export const alphanumericWithSpecial = /^[ A-Za-z0-9./\\-]*$/;
//export const phoneRegExp = /^[789]\d{9}$/;

export const phoneRegExp =
  "^(\\+\\d{1,3}( )?)?((\\(\\d{1,3}\\))|\\d{1,3})[- .]?\\d{3,4}[- .]?\\d{2}$";
export const country_codeReg = /^[+]?\d+$/;

export const panRegExp = /^[A-Z]{5}[0-9]{4}[A-Z]{1}?$/;
//export const faxRegExp = /^\+?[0-9]+$/;
export const faxRegExp = /^[0-9]*$/;
export const vatRegExp =
  /^[A-Za-z]{2,4}(?=.{2,12}$)[-_\s0-9]*(?:[a-zA-Z][-_\s0-9]*){0,2}$/;
export const emailRegExp =
  /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
export const ssnOrTinRegex = /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/;
export const pincode = /^\d{6}$/;
export const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

export const decimal = /^(\d*\.)?\d+$/;

export const encryptSecret = "@CATALOG$#123@#456";
export const areaRegExp = /^(\d{1,5}|\d{0,5}\.\d{1,2})$/;
export const alphaRegExp = /^[A-Za-z ]+$/;
export const regUnitNo = /^[0-9]+$/;
export const PDF_FORMATS = ["application/pdf"];
export const year = /^(?:(?:19|20)[0-9]{2})$/;
export const FILE_FORMATS = [
  "application/pdf",
  "application/doc",
  "application/docx",
];

export const EXCEL_FORMATS = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.ms-excel",
  "application/xlsx",
  "application/xls",
  "application/XLSX",
  "application/XLS",
];
export const FILE_SIZE = 160 * 1024;

export const allTypes = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/doc",
  "application/docx",
];
export const alphaName = /^[A-Za-z\s]+$/;

export const URLinks =
  /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/;
export const dec_two_places = /^(\d{1,5}|\d{0,5}\.\d{1,2})$/;
export const bank_ac_no = /^\d{9,18}$/;
export const bank_ifsc_code = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const percentage =
  /^(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)$/;
export const IMAGES = ["image/jpg", "image/jpeg", "image/png"];

export const OTP = /^[0-9]\d{4}$/;
export const vehiclenoFormat =
  /^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/;

export const amountFormat = /^\d[\d,]*(\.\d+)?$/;
export const gmailIcon = "/assets/images/gmail-icon.png";
export const whatsappIcon = "/assets/images/whatsapp-icon.png";

//particular 3digits after comma
//export const amountFormat = /^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\.\d+)?$/;
