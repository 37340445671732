export const login = `/login`;
export const register = "/register";
export const catalogueview = "/catalogue";
export const catalogDirectLinks = "/catalog-direct-links";
export const cataloglist = "/catalogueList";
//export const catalogview = "/catalog/:filename";
export const catalogview = "/catalog/:filename/:pagetitle/:fileId";
export const storeLocator = "/store-locator";
//export const webRedirection = "/web-redirection/:link";
export const webRedirection = "/web-redirection/";
export const utmLinks = "/utm-links";