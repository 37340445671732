import { useEffect } from "react"
import { connect } from "react-redux";
import {getCatalogList} from "../../../redux/AllThunk/Catalog";
import CatalogCard from "./CatalogCard";
import { getLogo } from "../../../helper";
import RegistrationPopup from "../Register/Popup";

const stateKey = "catalogList"
const CatalogList = (props)=>{

    useEffect(()=>{
        props.dispatch(
            getCatalogList({
              payload: {'company_id':1},
              key: stateKey,
            })
        );
    },[])

    return (
        <main>
            {localStorage.registerd_email ==undefined  && (<RegistrationPopup  />)}
            <div className="container-fluid">
                    <div className="col-sm-12">
                        <div className="d-flex">
                            <div className="col-md-1 col-sm-4 col-4 justify-content-start">
                                <img src={getLogo()} className="logo" />
                            </div>
                            <div className="col-md-11 col-sm-8 col-8 text-center">
                                <h1 className="page-title">All Catalogs</h1>
                            </div>
                        </div>
                        <div className="row card-row">
                            {
                                props.list?.data?.length!= 0 &&
                                props.list?.data.map((catalog, i) => {
                                    return (
                                        <CatalogCard
                                            key={i}
                                            catalog={catalog}
                                        />
                                    );
                                })
                            } 
                        </div>
                    </div>
            </div>
		</main>
    )
}
export default connect((state)=>{
    return {list: {...state.GetData?.[stateKey]}}
})(CatalogList)