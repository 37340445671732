import axios from "axios";

const instance = axios.create({});

instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["Accept"] = "application/json";

const sess_data = sessionStorage.getItem("data");
const sess_json = JSON.parse(sess_data);

sess_json?.token &&
  (instance.defaults.headers.common["Authorization"] =
    "Bearer " + sess_json?.token);

instance.interceptors.response.use(
  (response) => {
    sessionStorage.removeItem("serverError");
    return response;
  },
  (error) => {
    return error;
  }
);

export default instance;

//=========================================================//

export const multipart_instance = axios.create({});

multipart_instance.defaults.headers.common["Accept"] = "multipart/form-data";
multipart_instance.defaults.headers.common["Content-Type"] =
  "multipart/form-data";
multipart_instance.defaults.headers.common["Authorization"] =
  "Bearer " + sess_json?.token;

multipart_instance.interceptors.response.use(
  (response) => {
    sessionStorage.removeItem("serverError");
    return response;
  },
  (error) => {
    return error;
  }
);

//=========================================

export const wp_instance = axios.create({});
wp_instance.defaults.headers.common["Content-Type"] = "application/json";
wp_instance.defaults.headers.common["Accept"] = "application/json";
wp_instance.defaults.headers.common["Authorization"] = process.env.REACT_APP_WHATSAPP_API_TOKEN 
