import { useEffect } from "react"
import { connect } from "react-redux";
import {getCatalogDirectLinksList} from "../../../redux/AllThunk/Catalog";
import { useNavigate,Link } from "react-router-dom";
import { catalogueview,catalogview } from "../../../Routes";
import { getUtmUrl,encrypt,decrypt } from "../../../helper";



const stateKey = "catalogDirectLinkList"
const DirectLinks = (props)=>{

    useEffect(()=>{
        props.dispatch(
            getCatalogDirectLinksList({
              payload: {'company_id':1},
              key: stateKey,
            })
        );
    },[])

    return (<>
    <h1>Catalog Direct Links List</h1>

    <div>{props.list?.data?.length>0 && props.list?.data?.map((i,v)=>{
        const utmurl = getUtmUrl(i);
        const encryptedfile =  encrypt(i.file);
        console.log('soniya---',i,encryptedfile);
       
        //     return (<>
        //      <div>
        //     {i.name}<br/>{i.file}<br/>{encryptedfile}<br/>
        // </div>
        //     <div key={v} className="m-2" style={{"marginBottom":'1rem','wordBreak':'break-all'}}><a href={'/catalog/'+i.qr_link+utmurl}>{i.name} <br/> {'/catalog/'+i.qr_link+utmurl}</a><br/></div></>)

return (<>
   
   <div key={v} className="m-2" style={{"marginBottom":'1rem','wordBreak':'break-all'}}><a href={'/catalog/'+i.qr_link+'/'+i.name+'/'+utmurl}>{i.name} <br/> {'/catalog/'+i.qr_link+'/'+i.name+'/'+utmurl}</a><br/></div></>)
        })}
    </div>
    
    </>)
}
export default connect((state)=>{
    return {list: {...state.GetData?.[stateKey]}}
})(DirectLinks)