import axios from "../interceptor";
import { api_base, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from "../../constants";

export const register = (action) => {
    return (dispatch) => {
      axios({
        url: api_base + "/customer/register",
        method: "post",
        data: action.payload,
      }).then(
        (response) => {
            //console.log('...',response,response.data);
          if (response?.status == HTTP_OK) {
            dispatch({
              type: "FORM_SUCCESS",
              payload: response?.data?.message,
              key: action.key,
            });
          } else {
            dispatch({
              type: "FORM_ERROR",
              payload: response?.response?.data?.data,
              moreError: response?.response?.data?.data,
              key: action.key,
            });
          }
        },
        (error) => {
          dispatch({
            type: "FORM_ERROR",
            payload: error?.response?.data?.data,
            moreError: error?.response?.data?.data,
            key: action.key,
          });
        }
      );
    };
  };
  