import React from "react";
import RedirectingView from "./common/RedirectingView";
import { Link } from "react-router-dom";
import { webRedirection } from "../../Routes";
import { hosturl } from "../../constants";

const UtmLinks = ()=>{
    return (
    <>
        <Link to={`${webRedirection}?utm_source=TradeFair&utm_medium=Listing_Click&utm_campaign=TradeFair_Global_Traveller&utm_content=Global_Traveller&link=https://www.nesterra.com/store-locator/`}>{hosturl+webRedirection}?utm_source=TradeFair&utm_medium=Listing_Click&utm_campaign=TradeFair_Global_Traveller&utm_content=Global_Traveller&link=https://www.nesterra.com/store-locator/</Link>
        <br/>

        <Link to={`${webRedirection}?utm_source=Nesterra-Store-Locator&utm_medium=Whatsapp?&utm_campaign=Store-Locator&utm_content=Store-Locator&link=https://www.nesterra.com/store-locator/`}>{hosturl+webRedirection}?utm_source=Nesterra-Store-Locator&utm_medium=Whatsapp?&utm_campaign=Store-Locator&utm_content=Store-Locator&link=https://www.nesterra.com/store-locator/</Link>
        <br/>

        <Link to={`${webRedirection}?utm_source=Nesterra-website&utm_medium=Whatsapp?&utm_campaign=Moodboard&utm_content=Blue-Moodboard&link=https://www.nesterra.com`}>{hosturl+webRedirection}?utm_source=Nesterra-website&utm_medium=Whatsapp?&utm_campaign=Moodboard&utm_content=Blue-Moodboard&link=https://www.nesterra.com</Link>
        <br/>
        <Link to={`${webRedirection}?utm_source=Nesterra-website&utm_medium=Whatsapp?&utm_campaign=Moodboard&utm_content=Beige-Moodboard&link=https://www.nesterra.com`}>{hosturl+webRedirection}?utm_source=Nesterra-website&utm_medium=Whatsapp?&utm_campaign=Moodboard&utm_content=Beige-Moodboard&link=https://www.nesterra.com</Link>

        <br/>

        
        
    </>
    )
}

export default UtmLinks