import React from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { register,catalogueview,cataloglist,catalogDirectLinks,
  catalogview,storeLocator,
  webRedirection,
  utmLinks
 } from './Routes';
import Register from './views/components/Register';
import CatalogueView from './views/components/CatalogueView/index1.js';
import Home from './views/components/Home';
import CatalogList from './views/components/CatalogList';
import DirectLinks from './views/components/DirectLinks';
import Catalogview from './views/components/Catalogview';
import StoreLocator from './views/components/StoreLocator';
import RedirectingView from './views/components/common/RedirectingView';
import UtmLinks from './views/components/UtmLinks';
// import ReactGA from 'react-ga';

// const TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID;
// ReactGA.initialize(TRACKING_ID);

function App() {
  // let data = sessionStorage.getItem("data");
  // const dataobj = JSON.parse(data);
  return (
    <>
      <BrowserRouter>
        <Routes>
        <Route
            path="/"
            element={
              /*<Home />*/
              <CatalogList />
            }
            
          />
          <Route
            path={catalogueview}
            element={
              <CatalogueView />
            }
          />
          <Route
            path={register}
            element={
              <Register />
            }
          />
          <Route
            path={catalogDirectLinks}
            element={
              <DirectLinks />
            }
          />
          <Route
            path={cataloglist}
            element={
              <CatalogList />
            }
          />
          <Route
            path={catalogview}
            element={
              <Catalogview />
            }
          />
          
          <Route path={storeLocator}
          element={
            <StoreLocator />
          }
          />
          <Route path={utmLinks}
          element={
            <UtmLinks />
          }
          />


          <Route path={webRedirection}
          element={
            <RedirectingView />
          }
          />
       </Routes>
      </BrowserRouter>
    </>
    );
}
export default App