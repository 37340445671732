const Forms = function (
    state = {
      Login: {
        loading: true,
        error: false,
        success: false,
        message: "",
      },
      Logout: {
        loading: true,
        error: false,
        success: false,
        message: "",
      },
      UserRegister: {
        loading: true,
        error: false,
        success: false,
        message: "",
        moreError: [],
      },
      shareOnEmail: {
        loading: true,
        error: false,
        success: false,
        error_msg: "",
        data: "",
      },
      shareOnWhatsapp: {
        loading: true,
        error: false,
        success: false,
        error_msg: "",
        data: "",
      },

    },
    action
  ) {
    switch (action.type) {
      case "RESET_FORM": {
        state = { ...state };
        state[action.key]["loading"] = true;
        state[action.key]["success"] = false;
        state[action.key]["error"] = false;
        state[action.key]["moreError"] = [];
        state[action.key]["message"] = "";
        return state;
      }
      case "FORM_SUCCESS": {
        state = { ...state };
        state[action.key]["success"] = true;
        state[action.key]["error"] = false;
        state[action.key]["message"] = action.payload;
        state[action.key]["loading"] = false;
        state[action.key]["moreError"] = [];
        state[action.key]["data"] = action?.data;
        return state;
      }
      case "FORM_ERROR": {
        state = { ...state };
        state[action.key]["success"] = false;
        state[action.key]["error"] = true;
        state[action.key]["message"] = action.payload;
        state[action.key]["moreError"] =
          action.moreError !== undefined ? action.moreError : [];
        state[action.key]["loading"] = false;
        return state;
      }
      default:
        return state;
    }
  };
export default Forms;  