import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
  faCircleXmark,
  faXmark,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomModal = (props) => {
  const [show, setShow] = useState(true);
  const [showMore, setShowMore] = useState(true);

  const handleClose = () => {
    setShow(false);
    if (props.stateKey !== undefined) {
      props.dispatch({
        type: "RESET_FORM",
        key: props.stateKey,
      });
    }
  };

  const customClass = props.type === "success" ? "text" : "errorText";
  const customModal = props.type === "success" ? "successModal" : "ErrorModal";
  let moreErrors =
    props.moreError !== undefined && props.moreError.length > 0
      ? props?.moreError.map((each, i) => {
          i++;
          return (
            <React.Fragment key={i}>
              <div className="py-2 border-bottom text-left">{`${each}`}</div>
            </React.Fragment>
          );
        })
      : [];

  return (
    <Modal
      show={show}
      className={`${customModal}`}
      onHide={() => {
        if (props?.restrictClose) {
          return false;
        } else {
          handleClose();
        }
      }}
    >
      <Modal.Body>
        {props.type == "error" && (
          <>
            <FontAwesomeIcon icon={faCircleXmark} onClick={handleClose} /> Error
            <FontAwesomeIcon
              icon={faXmark}
              onClick={handleClose}
              //style={{ float: "right" }}
              className="float-end"
            />
            {moreErrors.length > 0 && (
              <div className={`${customClass}`}>{moreErrors}</div>
            )}
          </>
        )}
        {props.type == "success" && (
          <>
            <FontAwesomeIcon icon={faCircleCheck} /> Success
            <FontAwesomeIcon
              icon={faXmark}
              onClick={handleClose}
              style={{ float: "right" }}
            />
            {props?.body && (
              <div className={`${customClass}`}>{props?.body}</div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default connect()(CustomModal);
