import React, { useState, useEffect } from "react";


const CatalogueView = (props) => {
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);

  //const samplepdf = 'https://admin.willyourwish.com/uploads/pdf/19/Sk_will.pdf';
  const samplepdf = 'https://www.nesterra.com/pub/media/collection/tmp/pdf/g/l/global_traveller_catalogue_1.pdf';

  // const renderPage: RenderPage = (props: RenderPageProps) => {
  //   return (
  //     <div style={{ userSelect: "none" }}>
  //       {props.canvasLayer.children}
  //       <div
  //         style={{
  //           alignItems: "center",
  //           display: "flex",
  //           height: "100%",
  //           justifyContent: "center",
  //           left: 0,
  //           position: "absolute",
  //           top: 0,
  //           width: "100%",
  //         }}
  //       ></div>
  //       {props.annotationLayer.children}
  //       {props.textLayer.children}
  //     </div>
  //   );
  // };

  return (
      <div className="d-flex justify-content-center" style={{width:'100%',height:'100vh'}}>
        <iframe style={{height:"100%",width:'100%'}} loading="lazy" src={'https://flowpaper.com/flipbook/'+samplepdf}></iframe>
      </div>
    );
};

export default CatalogueView;
