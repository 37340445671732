import React from "react";
import { Link } from "react-router-dom";
import { getUtmUrl,encrypt } from "../../../helper";
import { web_base_url } from "../../../constants";

const CatalogCard = ({catalog}) => {
  const utmurl = getUtmUrl(catalog.utm_detail);
  let link = '/catalog/'+encrypt(web_base_url+catalog.file)+'/'+catalog.name+'/'+encrypt(catalog.id)+'/'+utmurl;

    return (
    <div className="col-sm-6 col-xl-4 col-xxl-3">
        <div className="card__list--wraper">
            <div className="card card__list ">
                
                    <div className="card__list--image">
                    <Link to={link}>
                        <img src={web_base_url+catalog.cover_img} />
                        </Link>
                    </div>
                    <div className="card-body">             
                        <div className="card__list--price-details">
                        <div className="row">
                            <Link to={link} className="product-title">{catalog.name}</Link>
                        </div>
                        </div>
                    </div>
                
            </div>
        </div>
    </div>
    );
};

export default CatalogCard;
