import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, useFormik, FormikProvider } from "formik";
import { initialValues, validationSchema } from "./util";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { register } from "../../../redux/AllThunk/Register";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import Loader from "../common/Loader";
import { getCompanyId } from "../../../helper";

const stateKey = "UserRegister";

const Popup = (props) => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  //const [show, setShow] = useState(props.show);

  // useEffect(()=>{ 
  //   console.log('--------------------------',props.show); setShow(props.show)
  // },[props.show])

  const submitHandler = (values) => {
    if (formik.isValid === true) {
      setLoading(true);

      const formData = {
        name: values.name,
        //phone: '91'+values.phone,
        phone: values.phone,
        country_code: values.country_code,
        email:values.email,
        company_name: values.company_name,
        city:values.city,
        company_id:getCompanyId()
      };
      
      props.dispatch(
        register({
          payload: formData,
          key: stateKey,
        })
      );  
    }
    //values.user_captcha = "";
  };
  const formik = useFormik({
    initialValues: initialValues,
    values: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    setLoading(false);
    if (props.formResult.success == true){

      const userData = {
        name: formik.values.name,
        phone: '91'+formik.values.phone,
        email:formik.values.email,
        company_name: formik.values.company_name,
        city:formik.values.city,
      };
        localStorage.setItem("registerd_email",formik.values.email);
        localStorage.setItem("userData",JSON.stringify(userData));
        formik.resetForm();
        setTimeout(function () {
            props.dispatch({
                type: "RESET_FORM",
                key: stateKey,
            });
            setShow(false);
        }, 2000);
    }
  }, [props.formResult]);

  const Cancel = () => {
    props.show ==undefined && setShow(false);
    props.show !=undefined && props.show(false);
  };
  return (
    <Modal show={show}
        onHide={() => {
          return false;
        }}
      >
        <FontAwesomeIcon
          icon={faCircleXmark}
          onClick={() => {
            Cancel();
          }}
          className="modalCloseIcon"
        />
        <Modal.Body>
          {loading && <Loader /> }
        <div className="login-title text-center mb-2">Create Account</div>
        
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit} className="login-form">
            <Form.Group className="col-12">
              <Field name="name">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="text"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Full Name"
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group>
            <Form.Group className="col-12">
              <Field name="email">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="email"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Email Address"
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group> 
            
            <Form.Group className="col-12 d-flex align-items-baseline">
               <span  className="m-auto" style={{fontSize:'2rem'}}>+</span>
              <Form.Group className="col-lg-3 col-md-3 col-sm-3">
                <Field name="country_code">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Country Code"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group> 
              <Form.Group className="ms-2 col-lg-8 col-md-8 col-sm-8">
                <Field name="phone">
                  {({ field, meta }) => (
                    <>
                      <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Mobile Number"
                      />
                      {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                          {meta.error}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}
                </Field>
              </Form.Group>
            </Form.Group>
            

            {/* <Form.Group className="col-12">
                <Field name="phone">
                    {({ field, meta }) => (
                    <>
                        <Form.Control
                        {...field}
                        type="text"
                        autoComplete="off"
                        isInvalid={!!meta.error && !!meta.touched}
                        value={field.value ? field.value : ""}
                        placeholder="Mobile Number"
                        />
                        {meta.touched && meta.error && (
                        <Form.Control.Feedback type="invalid">
                            {meta.error}
                        </Form.Control.Feedback>
                        )}
                    </>
                    )}
                </Field>
            </Form.Group> */}

            <Form.Group className="col-lg-12 col-md-12 col-sm-12">
              <Field name="company_name">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="text"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="Company Name"
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group>

            <Form.Group className="col-lg-12 col-md-12 col-sm-12">
              <Field name="city">
                {({ field, meta }) => (
                  <>
                    <Form.Control
                      {...field}
                      type="text"
                      autoComplete="off"
                      isInvalid={!!meta.error && !!meta.touched}
                      value={field.value ? field.value : ""}
                      placeholder="City"
                    />
                    {meta.touched && meta.error && (
                      <Form.Control.Feedback type="invalid">
                        {meta.error}
                      </Form.Control.Feedback>
                    )}
                  </>
                )}
              </Field>
            </Form.Group>
                    
            {/* <Form.Check className="d-flex col-lg-9 col-md-9 col-sm-12 mt-3">
              <Field
                type="checkbox"
                name="terms"
                id="terms"
                className="form-check-input mt-0"
              />
              <label
                htmlFor="terms"
                className="register-checkbox col-lg-10 col-md-10 col-sm-8"
              >
                By clicking this box you are agreeing to the terms and
                conditions 
              </label>
            </Form.Check>
            {formik.errors.terms && formik.touched.terms == true && (
              <div className="field-error mt-2">{formik.errors.terms}</div>
            )} */}
            <div className="col-md-12 col-sm-12 m-auto text-center">
                {props.formResult.success !== true && (
                    <button className="button btn btn__primary  mt-auto" type="submit">
                    Register
                    </button>
                )}
                {props.formResult.success == true && (
                    <div className="successNote">
                    Thank you for sharing the details... 
                    </div>
                )}
            </div>           
          </Form>
      </FormikProvider>
    </Modal.Body>
  </Modal>
  );
};

export default connect((state) => {
  return { formResult: { ...state?.Forms?.[stateKey] } };
})(Popup);
