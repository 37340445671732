import { useRef, useEffect } from 'react'

function useSeo(data = {}, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const defaultMetaDescription = useRef(document.querySelector('meta[name="description"]'));

  useEffect(() => {
    document.title = data.title;
  }, [data.title]);

  useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
  }, [data.metaDescription]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
      document.querySelector('meta[name="description"]').setAttribute('content', defaultMetaDescription);
    }
  }, [])
}

export default useSeo