import * as Yup from "yup";
import {
  passwordRegex,
  notOnlyWhiteSpace,
  phoneRegExp,
  numeric,
  emailRegExp,
} from "../../../constants";

export const initialValues = {
    name: "",
    email:"",
    phone: "",
    country_code: "91",
    company_name:"",
    city: "",
    //terms: false,
};
export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "*Name must have at least 2 characters")
    .max(100, "*Name can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    )
    .required("*Name is required"),
  email: Yup.string()
    .required("*This is required")
    .matches(emailRegExp, "*Please enter valid email address "),
  country_code: Yup.string()
    .required("*Country Code required")
    .matches(numeric, "*Country Code is not valid")
    .max(3, "*Country Code can not be more than 3 digits")
    .min(1, "*Country Code must be minimum 1 digits"),
  phone: Yup.string()
    .required("*Mobile number required")
    .matches(numeric, "*Mobile number is not valid")
    .max(12, "*Mobile number can not be more than 12 digits")
    .min(6, "*Mobile number must be minimum 6 digits"),
  city: Yup.string()
    .min(2, "*city must have at least 2 characters")
    .max(100, "*city can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    )
    .required("*City is required"),
  company_name: Yup.string()
    .min(2, "*Company Name must have at least 2 characters")
    .max(100, "*Company Name can't be longer than 100 characters")
    .matches(
      notOnlyWhiteSpace,
      "*Only Alphabets,Numbers and Spaces are allowed "
    )
    .required("*Company Name is required"),
 
  //terms: Yup.bool().oneOf([true], "*Please accept the terms and conditions"),
});
