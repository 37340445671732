import { useEffect,useState } from "react";
import {  Link, useParams } from "react-router-dom";
import { decrypt,getLogo } from "../../../helper";
import Loader from "../common/Loader";
import useSeo from "../../../CustomHooks/useSeo";
import { gmailIcon,whatsappIcon } from "../../../constants";
import {cataloglist } from  "../../../Routes";
import {share_via_email,share_via_whatsapp} from "../../../redux/AllThunk/Share";
import { connect } from "react-redux";
import CustomModal from "../common/CustomModal";
import RegistrationPopup from "../Register/Popup";
import {Whatsapp_msg_template} from "../../../helper";
//import ReactGA from 'react-ga';
import gtag from 'ga-gtag'

const pdfViewer = 'https://flowpaper.com/flipbook/';
const EmailKey = "shareOnEmail";
const WhatsappKey = "shareOnWhatsapp";

const Catalogview = (props)=>{

    const [loading, setLoading] = useState(false);
    const [showRegistrationPopup, setShowRegistrationPopup] = useState(false);
    const [shareVia, setShareVia] = useState(null);

    let {filename,pagetitle,fileId} = useParams();
    useSeo({'title':pagetitle,'metaDescription':'Catalog View'});
    //useEffect(()=>{
      //window.location.href = pdfViewer+decrypt(filename);
    //},[])

    const filepath = pdfViewer+decrypt(filename);

    const shareOnGmail = () => {
        const regsiteredEmail = localStorage.getItem("registerd_email");
        if(regsiteredEmail ==null){
            setShowRegistrationPopup(true);
        }else{
            setLoading(true);

            gtag('event', 'gmail_share', {
                catalog_title: pagetitle,
            });
            // ReactGA.event({
            //     category: 'gmail_share_ReactGA',
            //     action: 'gmail Share: '+pagetitle
            // });

            props.dispatch(
                share_via_email({
                payload: { filepath: decrypt(filename),fileId:decrypt(fileId),receiver:regsiteredEmail },
                key: EmailKey,
                })
            );
        }
    };

    const shareOnWhatsapp = ()=>{
        const userData = localStorage.getItem("userData");
        const regsiteredNo = localStorage.getItem("regsiteredNo");
        if(userData ==null){
            setShowRegistrationPopup(true);
        }else{
            setLoading(true);
            const user_data = JSON.parse(userData);
            const template = Whatsapp_msg_template({ filepath: decrypt(filename),receiver:user_data.phone,catalogName:pagetitle,filename:pagetitle });

            gtag('event', 'whatsapp_share', {
                catalog_title: pagetitle,
            });
            // ReactGA.event({
            //     category: 'whatsapp_share_ReactGA',
            //     action: 'whatsapp shared : '+pagetitle
            // });
            props.dispatch(
                share_via_whatsapp({
                payload:template,
                key: WhatsappKey,
                })
            );
        }

    }

    useEffect(() => {
        if (props.emailShare.success == true || props.emailShare.error == true) {
          setLoading(false);
          setTimeout(function () {
            props.dispatch({
                type: "RESET_FORM",
                key: EmailKey,
            });
        }, 2000);
        }
    }, [props.emailShare]);

    useEffect(() => {
        if (props.whatsappShare.success == true || props.whatsappShare.error == true) {
          setLoading(false);
          setTimeout(function () {
            props.dispatch({
                type: "RESET_FORM",
                key: WhatsappKey,
            });
        }, 2000);
        }
    }, [props.whatsappShare]);

    useEffect(() => {
        if (props.register.success == true){
            setTimeout(function () {
                shareVia =="gmail"?shareOnGmail():shareOnWhatsapp();
        }, 2000);
        }
    }, [props.register]);

    console.log()

    return (<>
    {loading && <Loader /> }
    {showRegistrationPopup && <RegistrationPopup show={(e)=>setShowRegistrationPopup(e)} />}

    {props?.emailShare?.error === true && (
    <CustomModal
        body={props.emailShare.message}
        moreError={props.emailShare.moreError}
        stateKey={EmailKey}
        type="error"
    />
    )}
    {props?.emailShare?.success === true && (
    <CustomModal
        body={props.emailShare.message}
        stateKey={EmailKey}
        type="success"
    />
    )}

    {props?.whatsappShare?.error === true && (
    <CustomModal
        body={props.whatsappShare.message}
        moreError={props.whatsappShare.moreError}
        stateKey={WhatsappKey}
        type="error"
    />
    )}
    {props?.whatsappShare?.success === true && (
    <CustomModal
        body={props.whatsappShare.message}
        stateKey={WhatsappKey}
        type="success"
    />
    )}

    <div className="d-flex  flex-column" style={{width:'100%',height:'100vh'}}>
        <div className="d-flex">
            <div className="col-3 col-sm-1 col-md-1"><Link to={cataloglist}><img src={getLogo()} className="logo"/></Link></div>
            <div className="col-6 col-sm-9 col-md-9 catalog-title justify-content-center">{pagetitle}</div>
            <div className="col-3 col-sm-2 col-md-2 justify-content-center d-flex flex-row m-auto">
                <img
                    src={gmailIcon}
                    className="gmail-icon ms-2"
                    onClick={() => {
                        setShareVia("gmail");
                        shareOnGmail();
                    }}
                />
                <img src={whatsappIcon} className="whatsapp-icon mx-2" 
                    onClick={() => {
                        setShareVia("whatsapp");
                        shareOnWhatsapp();
                    }} 
                />
            </div>
        </div>
        <iframe style={{height:"100%",width:'100%'}} loading="lazy" src={filepath}></iframe>
    </div>
           
    
    {/* <div className="col-4 text-center">
        <img
        src={downloadIcon}
        className="download-icon w-50"
        onClick={() => {
            // downloadCurrentAssetSummary();
            setDownloadLink(props.result.data.downloadLink);
        }}
        />
    </div> */}
    </>)
}
export default connect((state)=>{
    return {
        whatsappShare: { ...state?.Forms?.[WhatsappKey] },
        emailShare: { ...state?.Forms?.[EmailKey] },
        register: { ...state?.Forms?.UserRegister } 
    };
})(Catalogview)