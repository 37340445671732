import { encryptSecret,whatsapp_clientId } from "../constants";
//export const subDirectory = process.env.REACT_APP_SUB_DIRECTORY;

var CryptoJS = require("crypto-js");

export function getUtmUrl(data){

    let utmParam = '?';
    utmParam += data.utm_source !==null ? 'utm_source='+data.utm_source+'&':"";
    utmParam += data.utm_medium !==null ? 'utm_medium='+data.utm_medium+'&':"";
    utmParam += data.utm_campaign !==null ? 'utm_campaign='+data.utm_campaign+'&':"";
    utmParam += data.utm_term !==null ? 'utm_term='+data.utm_term+'&':"";
    utmParam += data.utm_content !==null ? 'utm_content='+data.utm_content+'&':"";
    utmParam += data.utm_id !==null ? 'utm_id='+data.utm_id+'&':"";
    
    utmParam = utmParam.slice(0, -1);
    return utmParam;
}

export function encrypt(data) {
    return CryptoJS.Rabbit.encrypt(JSON.stringify(data), encryptSecret)
    .toString()
    .replace(/\//g, "Por21Ld");
}
  
export function decrypt(data) {
    var bytes = CryptoJS.Rabbit.decrypt(
    data.replaceAll("Por21Ld", "/"),
    encryptSecret
    );
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export const assetPath = (file) => {
    //return subDirectory + "/assets/" + file;
    return "/assets/" + file;
};

export const getLogo = () => {
    return "/assets/images/logo.png" ;
};

export const getCompanyId=()=>{
    return 1;
}

export const Whatsapp_msg_template = (data)=>{
    const template = 
    {
        "clientId": whatsapp_clientId,
        "to": data.receiver,
        "type": "template",
        "category": "MARKETING",
        "typeBody": {
            "name": "neterra_catalog_send",
            "language": {
                "code": "en",
                "policy": "deterministic"
            },
            "components": [
                {
                    "type": "header",
                    "parameters": [
                        {
                            "type": "document",
                            "document": {
                                "link": data.filepath,
                                "filename":data.filename
                            }
                        }
                    ]
                },
                {
                    "type": "body",
                    "parameters": [
                        {
                            "type": "text",
                            "text": data.catalogName
                        },
                    ]
                }
            ]
        }
    }
    return template;
}