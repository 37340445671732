import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom"
const StoreLocator  = ()=>{
    useEffect(()=>{
        window.location.replace('https://www.nesterra.com/store-locator/')
  
    },[])
    return <h1>Redirecting.........</h1>;
}

export default StoreLocator