import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const RedirectingView  = ()=>{

    const [searchParams, setSearchParams] = useSearchParams();
    console.log('link',searchParams.get("link"));
    const link = searchParams.get("link");
    useEffect(()=>{
        window.location.replace(link);
  
    },[])
    return <h1>Redirecting.........</h1>;
}

export default RedirectingView